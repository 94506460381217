<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div style="display: flex;align-items: center;">
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label">用户ID: </label>
					<el-input v-model="searchData.userId" clearable style='width:250px' placeholder="请输入用户ID">
					</el-input>
				</div>
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label">用户账号: </label>
					<el-input v-model="searchData.phoneNumber" clearable style='width:250px' placeholder="请输入用户账号">
					</el-input>
				</div>
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label">用户昵称: </label>
					<el-input v-model="searchData.nickName" clearable style='width:250px' placeholder="请输入用户昵称">
					</el-input>
				</div>
				<div class="filter-item" style="margin-top: 10px;">
					<label class="label" style="margin-left: 20px;">等级:</label>
					<el-select v-model="searchData.pusherLevel" placeholder="请选择" filterable clearable
						style="width: 160px;margin-right: 10px;">
						<el-option v-for="(item,index) in levelList" :key="index" :label="item.str"
							:value="item.state"></el-option>
					</el-select>
				</div>
				<el-button type="primary" style="width:100px;margin-right:20px;" @click="search()">搜索</el-button>
			</div>

		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border @sort-change="sortChange" ref="multipleTable">
				<el-table-column prop="userId" label="用户ID"></el-table-column>
				<el-table-column prop="phoneNumber" label="用户账号"></el-table-column>
				<el-table-column label="等级">
					<template slot-scope="scope">
						<div>{{levelList[scope.row.pusherLevel+1].str}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="nickName" label="用户昵称"></el-table-column>
				<el-table-column label="内容" width="400px">
					<template slot-scope="scope">
						<div style="overflow:hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;padding:0 10px;">{{scope.row.content}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="trueLikeCount" label="真实点赞数" sortable></el-table-column>
				<el-table-column prop="virtualLikeCount" label="虚拟点赞数" sortable></el-table-column>
				<el-table-column prop="totalLikeCount" label="总点赞数" sortable></el-table-column>
				<el-table-column label="操作" width="300">
					<template slot-scope="scope">
						<button-permissions :datas="'editArticle'">
							<div class="table-button" @click="handleDataShow(scope.row)">编辑</div>
						</button-permissions>
						<button-permissions :datas="'delDataArticle'">
							<div class="table-button" @click="sureDel(scope.row)">删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="searchData.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]" :page-size="searchData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="searchData.total"></el-pagination>
		</div>
		<!-- 编辑 -->
		<el-dialog v-if="currentRow" title="编辑" :visible.sync="auditPopShow" width="800px">
			<el-form label-width="120px" class="demo-ruleForm">
				<el-form-item label="用户ID:" label-width="150px">
					<el-input :disabled="true" v-model="currentRow.userId" style="width:300px"></el-input>
				</el-form-item>
				<el-form-item label="用户账号:" label-width="150px">
					<el-input :disabled="true" v-model="currentRow.phoneNumber" style="width:300px"></el-input>
				</el-form-item>
				<el-form-item label="用户等级:" label-width="150px">
					<el-input :disabled="true" v-model="levelList[currentRow.pusherLevel+1].str" style="width:300px"></el-input>
				</el-form-item>
				<el-form-item label="内容:" label-width="150px" :required="true">
					<el-input type="textarea" :rows="8" v-model="currentRow.popContent" placeholder="请填写故事内容" style="width:600px" maxlength="500"></el-input>
				</el-form-item>
				<el-form-item label="真实点赞数:" label-width="150px">
					<el-input :disabled="true" v-model="currentRow.trueLikeCount" style="width:300px"></el-input>
				</el-form-item>
				<el-form-item label="虚拟点赞数:" label-width="150px" :required="true">
					<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" v-model.number="currentRow.popVirtualLikeCount" placeholder="0" style="width:300px"></el-input>
				</el-form-item>
				<div style="text-align: center;margin-top: 30px;">
					<el-button style="width: 120px;" @click="auditBefore(1)">取消</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="auditBefore(2)">保存</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		onlineActivityDelete,
		onlineActivityList,
		onlineActivityUpdate,
	} from '@/api/wyUsedInterface.js'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				searchData: {
					total: 0,
					pageNo: 1,
					size: 20,
					activityId:0,
					nickName:'',//用户昵称
					phoneNumber:'',//用户账号
					pusherLevel:null,//用户等级
					userId:'',//用户id
					totalLikeCountSort:'',//总点赞数排序 0倒序 1顺序
					trueLikeCountSort:'',//真实点赞数排序 0倒序 1顺序
					virtualLikeCountSort:'',//虚拟点赞数排序 0倒序 1顺序
				},
				loading: false,
				tableData: [],
				levelList:[
					{
						state: null,
						str: '全部'
					},
					{
						state: 0,
						str: '粉丝'
					},
					{
						state: 1,
						str: '1级'
					},
					{
						state: 2,
						str: '2级'
					},
					{
						state: 3,
						str: '3级'
					},
					{
						state: 4,
						str: '4级'
					}
				],
				auditPopShow:false,
				currentRow:null
			}
		},
		async beforeMount() {
			this.searchData.activityId = this.$route.query.id;
			this.getList();
		},
		computed: {
			
		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					if (this.applyTime) {
						this.searchData.applyStartTime = this.applyTime[0];
						this.searchData.applyEndTime = this.applyTime[1];
					} else {
						this.searchData.applyStartTime = '';
						this.searchData.applyEndTime = '';
					}
					let data = {
						pageNo: this.searchData.pageNo,
						pageSize: this.searchData.size,
						activityId: +this.searchData.activityId,
						nickName: this.searchData.nickName,
						phoneNumber: this.searchData.phoneNumber,
						pusherLevel: this.searchData.pusherLevel,
						userId: this.searchData.userId,
						totalLikeCountSort:this.searchData.totalLikeCountSort,
						trueLikeCountSort:this.searchData.trueLikeCountSort,
						virtualLikeCountSort:this.searchData.virtualLikeCountSort,
					};
					let result = '';
					result = await onlineActivityList(data);
					if (result.data) {
						result.data.records.map(item=>{
							item.popContent = item.content;
							item.popVirtualLikeCount = item.virtualLikeCount;
							return item;
						})
						this.tableData = result.data.records;
						this.searchData.total = result.data.total;
					}
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.searchData.isDownload = false; //下载文件标记
				if (!this.searchData.total) {
					this.searchData.size = 20
				}
				this.getList()
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			//排序变化触发方法
			sortChange(obj) {
				console.log(obj);
				this.initSort();
				if(obj.prop){
					if(obj.prop == "trueLikeCount"){
						if(obj.order){
							if (obj.order == "descending") {
								this.searchData.trueLikeCountSort = 1;
							} else if (obj.order == "ascending") {
								this.searchData.trueLikeCountSort = 0;
							} else {
								this.searchData.trueLikeCountSort = '';
							}
						}else{
							this.searchData.trueLikeCountSort = '';
						}
					}else if(obj.prop == "virtualLikeCount"){
						if(obj.order){
							if (obj.order == "descending") {
								this.searchData.virtualLikeCountSort = 1;
							} else if (obj.order == "ascending") {
								this.searchData.virtualLikeCountSort = 0;
							} else {
								this.searchData.virtualLikeCountSort = '';
							}
						}else{
							this.searchData.virtualLikeCountSort = '';
						}
					}else if(obj.prop == "totalLikeCount"){
						if(obj.order){
							if (obj.order == "descending") {
								this.searchData.totalLikeCountSort = 1;
							} else if (obj.order == "ascending") {
								this.searchData.totalLikeCountSort = 0;
							} else {
								this.searchData.totalLikeCountSort = '';
							}
						}else{
							this.searchData.totalLikeCountSort = '';
						}
					}
				}
				this.search();
			},
			initSort(){
				this.searchData.totalLikeCountSort = '';
				this.searchData.virtualLikeCountSort = '';
				this.searchData.trueLikeCountSort = '';
			},
			handleDataShow(row) {
				this.currentRow = row;
				this.currentRow.popVirtualLikeCount = this.currentRow.virtualLikeCount;
				this.currentRow.popContent = this.currentRow.content;
				this.auditPopShow = true;
			},
			async auditBefore(type){
				if(type==1){
					this.auditPopShow = false;
				}else{
					try{
						let data = {
							content:this.currentRow.popContent,
							id:this.currentRow.id,
							virtualLikeCount:this.currentRow.popVirtualLikeCount
						};
						let result = await onlineActivityUpdate(data);
						if(result.success){
							this.$message({
								showClose: true,
								type: 'success',
								message: '编辑成功'
							});
							this.auditPopShow = false;
							this.getList();
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg?result.alertMsg:'编辑失败'
							});
						}
					}catch(e){
						//TODO handle the exception
					}
				}
			},
			sureDel(row){
				this.$confirm('您确定要删除当前数据吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.promotionDel(row);
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			async promotionDel(row){
				try{
					let data = {
						id:row.id,
					};
					let result = await onlineActivityDelete(data);
					if(result.success){
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功'
						});
						this.auditPopShow = false;
						this.getList();
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg?result.alertMsg:'删除失败'
						});
					}
				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
